import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl = environment.apiUrl;
  private baseCommerceUrl = environment.apiCommerceUrl;
  private version = environment.version;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService
  ) { }

  isTokenExpired = new Subject<boolean>();

  checkTokenExpired() {
    const token = this.jwtHelper.tokenGetter().toString();
    if (token) {
      if (this.jwtHelper.isTokenExpired(token)) {
        this.isTokenExpired.next(true);
        return true
      } else {
        this.isTokenExpired.next(false);
        return false
      }
    } else {
      return
    }
  }


  // Authorizations
  login(payload: any) {
    return this.http.post<any>(`${this.baseUrl}auth/public/login`, JSON.stringify(payload), this.httpOptions);
  }

  register(payload: any) {
    return this.http.post<any>(`${this.baseUrl}auth/public/register`, JSON.stringify(payload), this.httpOptions);
  }

  loginBiometric(payload: any) {
    return this.http.post<any>(`${this.baseUrl}auth/public/login/biometric`, JSON.stringify(payload), this.httpOptions);
  }

  registerBiometric() {
    return this.http.get<any>(`${this.baseUrl}auth/public/register/biometric`, this.httpOptions);
  }

  changePassword(payload: any) {
    return this.http.post<any>(`${this.baseUrl}auth/public/change_password`, JSON.stringify(payload), this.httpOptions);
  }

  getOTP() {
    return this.http.get<any>(`${this.baseUrl}auth/public/otp`, this.httpOptions);
  }

  unhortenURL(payload: any) {

    return this.http.post<any>(`${this.baseUrl}v1/seller/unshorten`, JSON.stringify(payload), this.httpOptions);
  }

  // GET
  getData(url: string) {
    return this.http.get<any>(`${this.baseUrl}${this.version}${url}`, this.httpOptions);
  }

  getDataArray(url: string) {
    return this.http.get<any[]>(`${this.baseUrl}${this.version}${url}`, this.httpOptions);
  }

  getReligions() {
    return this.http.get<any[]>(`${this.baseUrl}v1/religions`, this.httpOptions);
  }

  getRaces() {
    return this.http.get<any[]>(`${this.baseUrl}v1/races`, this.httpOptions);
  }

  getBusiness(id: number) {
    return this.http.get<any>(`${this.baseUrl}v1/businesses/${id}`, this.httpOptions);
  }

  getDistricts() {
    return this.http.get<any>(`${this.baseUrl}v1/districts`, this.httpOptions);
  }

  getBusinessCategories() {
    return this.http.get<any>(`${this.baseUrl}v1/business_categories`, this.httpOptions);
  }

  getProductCategories() {
    return this.http.get<any>(`${this.baseUrl}v1/product_categories`, this.httpOptions);
  }

  getReportSalesByYearAndBusinessId(year: string, business_id: string) {
    return this.http.get<any>(`${this.baseUrl}${this.version}/reports/sales/business/${business_id}?year=${year}`, this.httpOptions);
  }

  getReportTopProductsByBusinessId(business_id: string) {

    return this.http.get<any>(`${this.baseUrl}${this.version}/reports/top/products/business/${business_id}`, this.httpOptions);
  }

  getReportTopCustomersByBusinessId(business_id: string) {

    return this.http.get<any>(`${this.baseUrl}${this.version}/reports/top/customers/business/${business_id}`, this.httpOptions);
  }

  getReportAccountHealth() {

    return this.http.get<any>(`${this.baseUrl}${this.version}/reports/business/health`, this.httpOptions);
  }

  async downloadCustomers(user_id: string, token: string, counter: string) {
    this.checkTokenExpired();
    let url = `${this.baseUrl}${this.version}/reports/customers/download?user=${user_id}&token=${token}&counter=${counter}`;
    let popup = window.open(url, '_blank');
    if (popup) {
      await popup.focus;
    }
    return popup?.focus;
  }

  async downloadOrders(user_id: string, token: string, counter: string, year: number, month: number) {
    this.checkTokenExpired();
    let url = `${this.baseUrl}${this.version}/reports/orders/download?user=${user_id}&token=${token}&counter=${counter}&year=${year}&month=${month}`;
    let popup = window.open(url, '_blank');
    if (popup) {
      await popup.focus;
    }
    return popup?.focus;
  }

  // POST
  postData(url: string, payload: any) {
    return this.http.post<any>(`${this.baseUrl}${this.version}${url}`, JSON.stringify(payload), this.httpOptions);
  }

  uploadPeopleFile(payload: any, formData: FormData) {

    const query = `${this.baseUrl}v1/attachments/upload/people`;
    const params = {
      is_person_cover_photo: payload.is_person_cover_photo,
      is_person_cover_photo_selected: payload.is_person_cover_photo_selected
    }
    const paramString = new URLSearchParams(params);
    return this.http.post<any>(`${query}?${paramString}`, formData);
  }

  uploadBusinessFile(payload: any, formData: FormData) {

    const query = `${this.baseUrl}v1/attachments/upload/businesses`;
    const params = {
      business_id: payload.business_id,
      is_business_cover_photo: payload.is_business_cover_photo,
      is_business_cover_photo_selected: payload.is_business_cover_photo_selected
    }
    const paramString = new URLSearchParams(params);
    return this.http.post<any>(`${query}?${paramString}`, formData);
  }

  uploadProductFile(payload: any, formData: FormData) {

    const query = `${this.baseUrl}v1/attachments/upload/products`;
    const params = {
      product_id: payload.product_id,
      is_product_cover_photo: payload.is_product_cover_photo,
      is_product_cover_photo_selected: payload.is_product_cover_photo_selected
    }
    const paramString = new URLSearchParams(params);
    return this.http.post<any>(`${query}?${paramString}`, formData);
  }

  uploadOrderFile(payload: any, formData: FormData) {

    const query = `${this.baseUrl}v1/attachments/upload/orders`;
    const params = {
      order_id: payload.order_id,
    }
    const paramString = new URLSearchParams(params);
    return this.http.post<any>(`${query}?${paramString}`, formData);
  }

  createBusiness(payload: any) {

    return this.http.post<any>(`${this.baseUrl}v1/businesses`, JSON.stringify(payload), this.httpOptions);
  }

  createProduct(payload: any) {

    return this.http.post<any>(`${this.baseUrl}v1/products`, JSON.stringify(payload), this.httpOptions);
  }

  // PUT
  putData(url: string, payload: any) {
    return this.http.put<any>(`${this.baseUrl}${this.version}${url}`, JSON.stringify(payload), this.httpOptions);
  }

  updateCurrentUser(payload: any) {
    return this.http.put<any[]>(`${this.baseUrl}v1/people`, JSON.stringify(payload), this.httpOptions);
  }

  updateBusiness(payload: any) {
    return this.http.put<any[]>(`${this.baseUrl}v1/businesses`, JSON.stringify(payload), this.httpOptions);
  }

  setPeopleCoverPhoto(param: any) {

    const query = `${this.baseUrl}v1/attachments/people/set_cover_photo`;
    const params = {
      attachment_id: param.attachment_id,
      person_id: param.person_id
    }
    const paramString = new URLSearchParams(params);
    return this.http.put<any>(`${query}?${paramString}`, this.httpOptions);
  }

  setBusinessCoverPhoto(param: any) {

    const query = `${this.baseUrl}v1/attachments/businesses/set_cover_photo`;
    const params = {
      attachment_id: param.attachment_id,
      business_id: param.business_id
    }
    const paramString = new URLSearchParams(params);
    return this.http.put<any>(`${query}?${paramString}`, this.httpOptions);
  }

  setProductCoverPhoto(param: any) {

    const query = `${this.baseUrl}v1/attachments/product/set_cover_photo`;
    const params = {
      attachment_id: param.attachment_id,
      product_id: param.product_id
    }
    const paramString = new URLSearchParams(params);
    return this.http.put<any>(`${query}?${paramString}`, this.httpOptions);
  }

  // DELETE
  deleteData(url: string) {

    return this.http.delete<any>(`${this.baseUrl}${this.version}${url}`, this.httpOptions);
  }

  deleteAttachment(attachment_id: number) {

    return this.http.delete<any>(`${this.baseUrl}${this.version}/attachments/${attachment_id}`, this.httpOptions);
  }


  //get ekan commerce
  getDataCommerce(url: string) {
    return this.http.get<any>(`${this.baseCommerceUrl}${this.version}${url}`, this.httpOptions);
  }


  //post ekan commerce
  postDataCommerce(url: string, payload: any) {
    return this.http.post<any>(`${this.baseCommerceUrl}${this.version}${url}`, JSON.stringify(payload), this.httpOptions);
  }


  //put ekan commerce
  putDataCommerce(url: string, payload: any) {
    return this.http.put<any>(`${this.baseUrl}${this.version}${url}`, JSON.stringify(payload), this.httpOptions);
  }


  //delete ekan commerce
  deleteDataCommerce(url: string) {
    return this.http.delete<any>(`${this.baseUrl}${this.version}${url}`, this.httpOptions);
  }
}
